import type { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';

import Sanity from 'clients/Sanity';
import { FeatureFlags } from 'clients/FeatureFlags';
import { useNewsletterContext } from 'contexts/NewsletterContext';

import { AppSettings, SanityPage } from 'types';
import { Nav, Footer, Content, Meta, Popups } from 'components';

const Page: NextPage<{ page: SanityPage; settings: AppSettings }> = ({ page, settings }) => {
  const router = useRouter();
  const newsletterContext = useNewsletterContext();

  // TO-DO: Refactor logic to be more concise
  if (!!router.query?.entry) {
    if (router.query.entry !== newsletterContext.campaign) {
      newsletterContext.setContext({
        ...newsletterContext,
        campaign: router.query.entry as string
      });
    }
  }
  if (!!router.query?.campaign) {
    if (router.query.campaign !== newsletterContext.campaign) {
      newsletterContext.setContext({
        ...newsletterContext,
        campaign: router.query.campaign as string
      });
    }
  }

  return (
    <div className="Home Page">
      <Meta page={page} settings={settings} />
      <Nav menu={settings.navigation.menu} />
      <main>
        <Content blocks={page.content} />
        <Popups />
      </main>
      <Footer menu={settings.footer.menu} />
    </div>
  );
};

export const getStaticProps: GetStaticProps = async function (context) {
  // TO-DO: Handle error here
  if (!context) return { props: {} };

  // Hard-coded for the index
  const SLUG = FeatureFlags.FULL_SITE.can() ? 'home' : 'shop';
  const page = await Sanity.pages.fetch(SLUG);
  const settings = await Sanity.settings.fetch();

  return {
    props: { slug: SLUG, page, settings },
    revalidate: 600
  };
};

export default Page;
